*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}
:root{
    --color-primary: #191d2b;
    --color-secondary: #27AE60;
    --color-white:#ffffff;
    --color-black: #000;
    --color-grey0: #f8f8f8;
    --color-grey-1: #dbe1e8;
    --color-grey-2: #b2becd;
    --color-grey-3: #6c7983;
    --color-grey-4: #454e56;
    --color-grey-5: #2a2e35;
    --color-grey-6: #12181b;
    --br-sm-2: 14px;
    --box-shadow-1: 0 3px 15px rgba(0,0,0,.3);
}
body{
    font-family: 'Poppins', sans-serif;
    background-color:var(--color-primary);
    font-size: 1.1rem;
    color: var(--color-white);
    transition: all .4s ease-in-out;
}
a{
    display: inline-block;
    text-decoration: none;
    color: inherit;
    font-family: inherit;
}
header{
    height: 100vh;
    color: var(--color-white);
    overflow: hidden;
}
section{
    min-height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    //padding: 3rem 18rem;
}
.section{
    transform: translateY(-100%);
    transition: all .4s ease-in-out;
    background-color: var(--color-primary);
}
.sec1{
    display: none;
    transform: translateY(0) scale(1);
}
.sec2{
    display: none;
    transform: translateY(0) scale(1);
}
.sec3{
    display: none;
    transform: translateY(0) scale(1);
}
.sec4{
    display: none;
    transform: translateY(0) scale(1);
}
.sec5{
    display: none;
    transform: translateY(0) scale(1);
}
.active{
    display: block;
    animation: scaleAnim 1s ease-in-out;
    @keyframes scaleAnim{
        0%{
            transform: translateY(-100%) scaleY(0);
        }
        100%{
            transform: translateY(0) scaleY(1);
        }
    }
}
//Controls
.controls{
    position: fixed;
    z-index: 10;
    top: 50%;
    right: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    .active-btn{
        background-color: var(--color-secondary) !important;
        transition: all .4s ease-in-out;
        i{
            color: var(--color-white) !important;
        }
    }
    .control{
        padding: 1rem;
        cursor: pointer;
        background-color: var(--color-grey-4);
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .7rem 0;
        box-shadow: var(--box-shadow-1);
        i{
            font-size: 1.2rem;
            color: var(--color-grey-2);
            pointer-events: none;
        }
    }
}
/*Header-content*/
.header-content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
    .left-header{
        display: flex;
        align-items: center;
        position: relative;
        .h-shape{
            transition: all .4s ease-in-out;
            width: 65%;
            height: 100%;
            background-color: var(--color-secondary);
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            clip-path: polygon(0 0, 46% 0, 79% 100%, 0% 100%);
        }
        .image{
            border-radius: var(--br-sm-2);
            height: 90%;
            width: 68%;
            margin-left: 4rem;
            background-color: var(--color-black);
            transition: all .4s ease-in-out;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .4s ease-in-out;
                filter: grayscale(100%);
                &:hover{
                    filter: grayscale(0);
                }
            }
        }
    }
    .right-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 18rem;
        .name{
            font-size: 3rem;
            span{
                color: var(--color-secondary);
            }
        }
        p{
            margin: 1.5rem 0;
            line-height: 2rem;
        }
    }
}
/*Timeline*/
.timeline{
    display: grid;
    grid-template-columns:repeat(2,1fr);
    grid-gap: 2rem;
    padding-bottom: 3rem;
    width: 100%;
    .timeline-item{
        position: relative;
        padding-left: 3rem;
        border-left: 1px solid var(--color-grey-5);
        .tl-icon{
            position: absolute;
            left: -27px;
            top: 0;
            background-color: var(--color-secondary);
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                font-size: 1.3rem;
            }
        }
        .tl-duration{
            padding: .2rem .6rem;
            background-color: var(--color-grey-5);
            border-radius: 15px;
            display: inline-block;
            font-size: .8rem;
            text-transform: uppercase;
            font-weight: 500;
        }
        h5{
            padding: 1rem 0;
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: 600;
            span{
                color: var(--color-grey-2);
                font-weight: 500;
                font-size: 1.2rem;
            }
        }
        p{
            color: var(--color-grey-2);
        }
    }
}
/*Portfolios*/
.port-text{
    padding: 2rem 0;
    text-align: center;
}
.portfolios{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 2rem;
    margin-top: 3rem;
    .portfolio-item{
        position: relative;
        border-radius: 15px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
        }
        .hover-items{
            width: 100%;
            height: 100%;
            background-color: var(--color-secondary);
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            opacity: 0;
            transform: scale(0);
            transition: all .4s ease-in-out;
            h3{
                font-size: 1.5rem;
                color: var(--color-white);
                margin-bottom: 1.5rem;
            }
            .icons{
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    background-color: var(--color-primary);
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 1rem;
                    cursor: pointer;
                    transition: all .4s ease-in-out;

                    i{
                        font-size: 1.5rem;
                        color: var(--color-white);
                        margin: 0 1rem;
                    }
                    &:hover{
                        background-color: var(--color-white);
                        i{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}
.portfolio-item:hover .hover-items{
    opacity:1;
    transform: scale(1);
}
/*Blogs Section*/
.blogs{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 2rem;
    margin-top: 3rem;
    .blog{
        position: relative;
        background-color: var(--color-grey-5);
        border-radius: 5px;
        box-shadow: 1px 1px 20px rgba(0,0,0,.1);
        transition: all .4s ease-in-out;
        &:hover{
            box-shadow: 1px 1px 20px rgba(0,0,0,.3);
            transform: translateY(-5px);
            transition: all .4s ease-in-out;
            img{
                filter: grayscale(0);
                transform: scale(1.1);
                box-shadow: 0px 4px 15px rgba(0,0,0,.8);
            }
        }
        img{
            width: 100%;
            //height: 300px;
            object-fit: cover;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            filter: grayscale(100%);
            transition: all .4s ease-in-out;

        }
        .blog-text{
            margin-top: -7px;
            padding: 1.1rem;
            border-top: 8px solid var(--color-secondary);
            h4{
                font-size: 1.5rem;
                margin-bottom: .7rem;
                transition: all .4s ease-in-out;
                cursor:pointer;
                &:hover{
                    color: var(--color-secondary);
                }
            }
            p{
                color: var(--color-grey-2);
                line-height: 2rem;
                padding-bottom: 1rem;
            }
        }
    }
}
//Contact section
.contact-content-con{
    display: flex;
    padding-top: 3.5rem;
    .left-contact{
        flex: 2;
        h4{
            margin-top: 1rem;
            font-size: 2rem;
            text-transform: uppercase;
        }
        p{
            margin: 1rem 0;
            line-height: 2rem;;
        }
        .contact-info{
            .contact-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    margin: .3rem 0 !important;
                    padding: 0 !important;
                }
                .icon{
                    display: grid;
                    grid-template-columns: 40px 1fr;
                    i{
                        display: flex;
                        align-items: center;
                        font-size: 1.3rem;
                    }
                }
            }
        }
        .contact-icons{
            display: flex;
            margin-top: 2rem;
            a{
                display: flex;
                align-items: center;
                color: var(--color-white);
                background-color: var(--color-grey-5);
                cursor:pointer;
                justify-content: center;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin: 0 .4rem;
                transition: all .4s ease-in-out;
                &:hover{
                    background-color: var(--color-secondary);
                    i{
                        color: var(--color-primary);
                    }
                }
                i{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.3rem;
                }
            }
        }
    }
    .right-contact{
        flex:3;
        margin-left: 3rem;
        .input-control{
            margin: 1.5rem 0;
            input, textarea{
                border-radius:30px;
                font-weight: inherit;
                font-size: inherit;
                font-family: inherit;
                padding: .8rem 1.1rem;
                outline: none;
                border: none;
                background-color: var(--color-grey-5);
                width: 100%;
                color: var(--color-white);
                resize: none;
            }
        }
        .i-c-2{
            display: flex;
            :last-child{
                margin-left: 1.5rem;
            }
        }
        .submit-btn{
            display: flex;
            justify-content: flex-start;
        }
    }
}
/*Independent components*/
.btn-con{
    display: flex;
    align-self: flex-start;
}
.main-btn{
    border-radius: 30px;
    color: inherit;
    font-weight: 600;
    position: relative;
    border: 1px solid var(--color-secondary);
    display: flex;
    align-self: flex-start;
    align-items: center;
    overflow: hidden;
    .btn-text{
        padding: 0 2rem;
    }
    .btn-icon{
        background-color: var(--color-secondary);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 1rem;
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        transition: all .4s ease-out;
        z-index: -1;
    }
    &:hover{
        &::before{
            width: 100%;
            height: 100%;
            background-color: var(--color-secondary);
            transform: translateX(0);
        }
    }

}
.main-title{
    text-align: center;
    h2{
        position: relative;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 700;
        span{
            color: var(--color-secondary);
        };
        .bg-text{
            position: absolute;
            top: 50%;
            left: 50%;
            color: var(--color-grey-5);
            transition: all .4s ease-in-out;
            z-index: -1;
            transform: translate(-50%, -50%);
            font-weight: 800;
            font-size: 6.3rem;
        }
    }
}
/*About*/
.about-container{
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 3.5rem;
    padding-bottom: 5rem;
    .right-about{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 2rem;
        .about-item{
            border: 1px solid var(--color-grey-5);
            border-radius: 5px;
            transition: all .4s ease-in-out;
            box-shadow: 1px 2px 15px rgba(0,0,0,.1);
            &:hover{
                cursor: default;
                transform: translateY(-5px);
                border: 1px solid var(--color-secondary);
                box-shadow: 1px 4px 15px rgba(0,0,0,.32);
            }
            .abt-text{
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                .large-text{
                    font-size: 3rem;
                    font-weight: 700;
                    color: var(--color-secondary);
                }
                .small-text{
                    padding-left: 3rem;
                    position: relative;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    color: var(--color-grey-1);
                    letter-spacing: 2px;
                    &::before{
                        content:'';
                        position: absolute;
                        left: 0;
                        top: 15px;
                        width: 2rem;    
                        height: 2px;
                        background-color: var(--color-grey-5);
                    }
                }
            }
        }
    };
    .left-about{
        padding-right: 5rem;
        p{
            line-height: 2rem;
            padding: 1rem;
            color: var(--color-grey-1);
        }
        h4{
            font-size: 2rem;
            text-transform: uppercase;
        }
    }
}
.about-stats{
    padding-bottom: 4rem;
    .progress-bars{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 2rem;
        .progress-bar{
            display: flex;
            flex-direction: column;
            .prog-title{
                text-transform: uppercase;
                font-weight: 500;

            }
            .progress-con{
                display: flex;
                align-items: center;
                .prog-text{
                    color: var(--color-grey-2);
                }
                .progress{
                    width: 100%;
                    height: .45rem;
                    background-color: var(--color-grey-4);
                    margin-left: 1rem;
                    position: relative;
                    span{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        background-color: var(--color-secondary);
                        transition: all .4s ease-in-out;
                        width: 60%;
                    }
                    .html{
                        width: 90%;
                    }
                    .css3{
                        width: 80%;
                    }
                }
            }

        }
    }
}
.stat-title{
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    padding: 3.5rem 0;
    position: relative;
    &::before{
        content:'';
        position: absolute;
        left: 50%;
        top: 0;
        width: 40%;
        height: 1px;
        background-color: var(--color-grey-5);
        transform: translateX(-50%);
    }
}
